import { CardHeader, ResponsiveContext } from 'grommet';
import { CircleInformation } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApiError, ClientService, TUpdateClientRequest } from '/src/api';
import {
  Box,
  BreadcrumbNav,
  ClientCorporateInformation,
  ClientGeneralInformation,
  ClientRemittanceInformation,
  CommissionsList,
  ContactsSelect,
  FacilityList,
  FormCard,
  FormPage,
  Line,
  StatementList,
  Tabs,
  UserList,
} from '/src/components';
import { config } from '/src/config';
import { useClientStore, useGlobalStore, useUserStore } from '/src/context';
import { RoleTypes, TClientId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { ClientDataForm, FormMetadata } from '/src/lib/types';
import { PageNotFound } from '/src/pages';
import { getFormErrors, getIsCanada, getIsMobile, getPageTitle } from '/src/utils';

export const ClientDetailsPage = observer(() => {
  /** Context **/
  const userStore = useUserStore();
  const globalStore = useGlobalStore();
  const clientStore = useClientStore();
  const screenSize = useContext(ResponsiveContext);
  const params = useParams();

  /** Refs **/
  const defaultFormData = useRef<ClientDataForm>({
    name: '',
    website: '',
    business_id: '',
    start_date: '',
    end_date: '',
    first_active_reporting_quarter: '',
    remittance_method_id: 1,
    bank_name: '',
    bank_account_type_id: NaN,
    routing_number: '',
    account_number: '',
    jumpstart_amount: '',
    comments: '',
    hq_address_line1: '',
    hq_address_line2: '',
    hq_address_city: '',
    hq_address_region_id: NaN,
    hq_address_post_code: '',
    remittance_address_line1: '',
    remittance_address_line2: '',
    remittance_address_city: '',
    remittance_address_region_id: NaN,
    remittance_address_post_code: '',
    programs: [],
  });

  /** State **/
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [focusedTab, setFocusedTab] = useState('');

  const [formValues, setFormValues] = useState<ClientDataForm>(defaultFormData.current);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isInvalidParams, setIsInvalidParams] = useState(false);

  /** Computed **/
  const clientId = parseInt(params.client_id ?? '');
  const isMobile = getIsMobile(screenSize);

  /** Methods **/
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateFormValue = (key: keyof ClientDataForm, value: any) => {
    setFormValues({
      ...formValues,
      [key]: value,
    });
    if (formErrors[key]) formErrors[key] = '';
  };

  const fetchPageData = async (clientId: TClientId) => {
    try {
      setIsLoading(true);

      const client = await ClientService.get({ id: clientId });
      clientStore.setSelectedClient(client);

      document.title = getPageTitle(config.client.basePageTitle, client.name);

      setFormValues({
        ...formValues,
        name: client.name,
        first_active_reporting_quarter: client.first_active_reporting_quarter,
        start_date: client.start_date,
        end_date: client.end_date ?? '',
        website: client.website ?? '',
        business_id: client.business_id ?? '',
        jumpstart_amount: '',
        remittance_method_id: client.remittance_method.id,
        bank_name: client.bank_name ?? '',
        bank_account_type_id: client.bank_account_type?.id ?? NaN,
        routing_number: client.routing_number ?? '',
        account_number: client.account_number ?? '',
        comments: client.comments,
        hq_address_line1: client.hq_address_line1 ?? '',
        hq_address_line2: client.hq_address_line2 ?? '',
        hq_address_city: client.hq_address_city ?? '',
        hq_address_region_id: client.hq_address_region?.id ?? NaN,
        hq_address_post_code: client.hq_address_post_code ?? '',
        remittance_address_line1: client.remittance_address_line1 ?? '',
        remittance_address_line2: client.remittance_address_line2 ?? '',
        remittance_address_city: client.remittance_address_city ?? '',
        remittance_address_region_id: client.remittance_address_region?.id ?? NaN,
        remittance_address_post_code: client.remittance_address_post_code ?? '',
      });
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.fetchClient.error);
    } finally {
      setIsLoading(false);
    }
  };

  const validateForm = () => {
    const formFields: FormMetadata = {
      name: { label: 'Name', max: 100, required: true },
      start_date: { label: 'Start date', required: true },
      website: { label: 'Website', max: 255 },
      business_id: { label: 'FEIN or Business Number', required: true, max: 20 },
      remittance_method_id: { label: 'Remittance method', required: true },
      comments: { label: 'Comments', max: 100 },
      hq_address_line1: { label: 'Address', max: 100, required: true },
      hq_address_line2: { label: 'Address', max: 100 },
      hq_address_city: { label: 'City', max: 50, required: true },
      hq_address_region_id: { label: 'Region', required: true },
      hq_address_post_code: { label: 'Postal code', max: 20, required: true },
      bank_account_type_id: { label: 'Bank account type', required: formValues.remittance_method_id === 1 },
      bank_name: { label: 'Bank name', max: 50, required: formValues.remittance_method_id === 1 },
      routing_number: { label: 'Routing number', max: 20, required: formValues.remittance_method_id === 1 },
      account_number: { label: 'Account number', max: 30, required: formValues.remittance_method_id === 1 },
      remittance_address_line1: { label: 'Address', max: 100, required: formValues.remittance_method_id === 2 },
      remittance_address_line2: { label: 'Address', max: 100 },
      remittance_address_city: { label: 'City', max: 50, required: formValues.remittance_method_id === 2 },
      remittance_address_region_id: { label: 'Region', max: 50, required: formValues.remittance_method_id === 2 },
      remittance_address_post_code: {
        label: 'Postal code',
        max: 20,
        required: formValues.remittance_method_id === 2,
      },
    };

    const errors = getFormErrors(formFields, formValues);
    if (errors) setFormErrors(errors);
    return !errors;
  };

  const updateClient = async () => {
    if (!validateForm()) return;

    const clientWebsite = formValues.website
      ? formValues.website.split('://').length === 1
        ? `https://${formValues.website}`
        : formValues.website
      : null;

    const updatedClient: TUpdateClientRequest = {
      id: clientId,
      name: formValues.name,
      website: clientWebsite,
      end_date: formValues.end_date || null,
      business_id: formValues.business_id,
      remittance_method_id: formValues.remittance_method_id,
      bank_name: formValues.bank_name || null,
      bank_account_type_id: formValues.bank_account_type_id || null,
      routing_number: formValues.routing_number || null,
      account_number: formValues.account_number || null,
      jumpstart_amount: null,
      comments: formValues.comments || null,
      hq_address_line1: formValues.hq_address_line1,
      hq_address_line2: formValues.hq_address_line2 || null,
      hq_address_city: formValues.hq_address_city,
      hq_address_region_id: formValues.hq_address_region_id,
      hq_address_post_code: formValues.hq_address_post_code,
      remittance_address_line1: formValues.remittance_address_line1 || null,
      remittance_address_line2: formValues.remittance_address_line2 || null,
      remittance_address_city: formValues.remittance_address_city || null,
      remittance_address_region_id: formValues.remittance_address_region_id || null,
      remittance_address_post_code: formValues.remittance_address_post_code || null,
    };

    try {
      setIsUpdating(true);
      const client = await ClientService.update(updatedClient);
      clientStore.setSelectedClient(client);
      setFormErrors({});
      toast.success(toastMessages.updateClient.success);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.updateClient.error);
    } finally {
      setIsUpdating(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    if (clientId && !globalStore.isLoading) {
      fetchPageData(clientId);
    }
  }, [clientId, globalStore.isLoading]);

  //404 Redirect
  useEffect(() => {
    if (!isLoading) {
      const invalid = Number.isNaN(clientId) || clientStore.selectedClient?.id === undefined;
      setIsInvalidParams(invalid);
    }
  }, [clientId, clientStore.selectedClient, isLoading]);

  /** Render **/
  return isInvalidParams ? (
    <PageNotFound />
  ) : (
    <FormPage
      title={clientStore.selectedClient?.name}
      breadcrumbNav={
        <BreadcrumbNav
          previousPages={[
            { name: 'Clients', link: !userStore.isClientUser && !userStore.isFacilityUser ? '/clients' : undefined },
          ]}
          currentPageName={clientStore.selectedClient?.name ?? ''}
        />
      }
      isLoading={isLoading}
      isDetailPage
      isClientPage
      clientId={clientId}
    >
      <Box gap="2rem">
        <FormCard
          title="Client Details"
          icon={<CircleInformation size="24px" color="brand" />}
          isLoading={isLoading || isUpdating}
          onSubmit={updateClient}
          hideSaveButton={activeTab === 1}
          hideRequiredText={activeTab === 1}
          header={
            <CardHeader pad="none">
              <Tabs
                titles={['Details', 'Contacts']}
                activeTab={activeTab}
                focusedTab={focusedTab}
                setActiveTab={setActiveTab}
                setFocusedTab={setFocusedTab}
              />
            </CardHeader>
          }
        >
          {activeTab === 0 && (
            <ClientGeneralInformation
              id="general_information"
              updateFormValue={updateFormValue}
              formValues={formValues}
              formErrors={formErrors}
              isCanada={getIsCanada(clientStore.selectedClient?.hq_address_region)}
              onSubmit={() => updateClient()}
              isUpdatePage
            />
          )}

          {activeTab === 0 && <Line margin="1rem" />}

          {activeTab === 0 && (
            <Box direction={isMobile ? 'column' : 'row'}>
              <Box id="corporate_information" width={isMobile ? '100%' : '50%'}>
                <ClientCorporateInformation
                  updateFormValue={updateFormValue}
                  formValues={formValues}
                  formErrors={formErrors}
                  onSubmit={() => updateClient()}
                />
              </Box>

              {isMobile ? <Line /> : <Line direction="vertical" margin="2rem" />}

              <Box id="remittance_information" width={isMobile ? '100%' : '50%'}>
                <ClientRemittanceInformation
                  updateFormValue={updateFormValue}
                  formValues={formValues}
                  formErrors={formErrors}
                  onSubmit={() => updateClient()}
                />
              </Box>
            </Box>
          )}

          {activeTab === 1 && (
            <ContactsSelect
              id="contacts"
              contacts={(clientStore.selectedClient?.users ?? []).filter(
                (user) => user.is_active && user.role.id === RoleTypes.ClientAdmin
              )}
              isCorporate
              isUpdatePage
            />
          )}
        </FormCard>
        <FacilityList id="facility_list" title="Facilities" clientId={clientId} showAddButton />
        <StatementList id="statement_list" title="Statements" clientId={clientId} />
        {userStore.user && userStore.isInternalUser && (
          <CommissionsList
            id="commission_list"
            title="Commissions"
            clientPrograms={clientStore.selectedClient?.client_programs}
            salesCommissions={clientStore.selectedClient?.sales_commission_rates}
          />
        )}
        <UserList
          id="user_list"
          title="Users"
          users={clientStore.selectedClient?.users}
          clientId={clientId}
          isClientPage
          showAddButton
        />
      </Box>
    </FormPage>
  );
});
